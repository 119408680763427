/*******************************
     User Global Variables
*******************************/

@pageBackground: #f3f4f5;
@primaryColor: #1c3a6a;
@liteGray:#D1CCBF;
@darkColor: #1a1e1e; 
@accent: #ae9142;
@translucent: rgba(255, 0, 0, 0.0);

.backgroundPrimary {
  background: @primaryColor;
}

.ui.search > .results{
     border: 0;
     background:@darkColor;
}

.ui.search > .results .result:hover,
.ui.category.search > .results .category .result:hover {
  background: gray;
}

.ui.divider:not(.vertical):not(.horizontal)  {
  border: solid 1px #FFFFFF;
  margin: 30px 0px;
}

.ui.secondary.pointing.menu .item {
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-radius: 0em;
    align-self: flex-end;
    margin: 0em 1em -1px 1em;
    padding: 0px 0px 5px 0;
    border-bottom-width: 1px; 
    border-bottom-height: 1px; 
   
}

.ui.segment {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 0 rgb(34 36 38 / 15%);
    margin: 0rem 0em;
    padding: 1em 1em;
    border-radius: 0rem;
    border: 0px solid rgba(34, 36, 38, 0.15);
}

